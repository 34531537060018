import React from 'react'
import Arrow from "../assets/Arrow.png";

export const Button = ( { noarrow, contenu, action, fontS = 24, pourcent = 80 } ) => {
  return (
    <div onClick={action} className="buttonbase" style={{ background: 'black', height: 70, width: pourcent + '%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>

        <p style={{ fontWeight: 700, textAlign: 'center', fontSize: fontS, color: 'white', padding: 10, margin: 0 }}>{contenu}</p>
        {!noarrow && <img src={Arrow} alt="arrow" style={{ width: 38, marginLeft: 10, marginRight: 10 }} />}
    </div>
  )
}
