import logo from './logo.svg';
import './App.css';
import { TestDePersonnalité } from './TestDePersonnalité';
import { Button } from './components/Button';
import { Accueil1 } from './components/Accueil1';
import { useEffect, useState } from 'react';
import { NavBar } from './components/NavBar';
import { MatchPage } from './components/MatchPage';
import { BottomNavigator } from './components/BottomNavigator';
import { Chat } from './components/Chat';
import { Profil } from './components/Profil';

function App() {
  
  const saison = 1;
  const [step, setStep] = useState(0);
  const [index, setNewIndex] = useState(1);

  const changeStep = (perso) => {
  
      setStep(step + 1);

  }

  useEffect(() => {
    let identifiant = window.localStorage.getItem('flammeId');
    if(identifiant) return;
    let createId = Math.floor(Math.random() * 1000000000);
    window.localStorage.setItem('flammeId', createId);
  }, [])

  useEffect(() => {

    let person = window.localStorage.getItem('testDePerso');
    if(!person) return;
    setStep(2);

  }, [])

  const backgroundcolor = saison === 1 ? '#B51636' : 'white';
  const newprops = { index, setNewIndex }

  console.log(step);

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, overflow: 'hidden', width: '100%', minHeight: '100vh', background: backgroundcolor }}>
      {step === 0 ? <Accueil1 prochaineEtape={() => setStep(1)} /> : step === 1 ?
      
      <>
        <NavBar noarrow title={'Test de personnalité'} />
        <TestDePersonnalité saison={saison} goNext={changeStep} />
      </>
      
      : index === 1 ?
      <>
        <NavBar title={'Match avec d\'autres fans'} />
        <MatchPage />
        <BottomNavigator {...newprops} />
      </> : index === 2 ?
      <>
        <NavBar title={'Chat'} />
        <Chat />
        <BottomNavigator {...newprops} />
      </> : 
      <>
        <NavBar title={'Profil'} />
        <Profil testAgain={() => setStep(1)}/>
        <BottomNavigator {...newprops} />
      </>
    } 

    </div>
  );
}

export default App;
