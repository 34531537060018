import React, { useEffect, useState } from 'react'
import Plus from '../assets/Plus.png'
import { personnages } from '../Personnages';
import Bateau from '../assets/Bateau.png'
import Ticket from '../assets/Ticket.png'
import Arrow from '../assets/ArrTopBlack.png'

const listePrenoms = ["Éden",
  "Camille",
  "Charlie",
  "Alix",
  "Andrea",
  "Noa",
  "Sasha",
  "Louison",
  "Élie",
  "Loïs",
  "Lou",
  "Sam",
  "Maé",
  "Jim",
  "Ange",
  "Swann",
  "Thaïs",
  "Kim",
  "Jo",
  "Jude",
  "Céleste",
  "Stéphane"];

const listeDescr = [
  "Les mots ne peuvent exprimer ma passion et mon amour pour les vendredis",
  "Le chocolat ne me pose jamais de questions, le chocolat me comprend.",
  "Parfois, je veux juste tout abandonner et devenir un beau milliardaire.",
  "J’ai peut-être l’air de ne rien faire, mais dans ma tête, je suis assez occupé.",
  "Mes loisirs sont le petit-déjeuner, le déjeuner et le dîner accompagnés d’un dessert au chocolat",
  "Hélas, je suis affligé de “génial”. Et il n’y a pas de remède.",
  "Attention : j’ai besoin de votre attention !",
  "Je n’arrive pas à me rappeler à qui j’ai volé cette biographie ni pour quelle raison.",
  "Libérez vos poches ! Donne-moi tout ton argent.",
  "Aussi chaud que je puisse être, mais je ne suis pas la cause de la fonte des glaces en Antarctique.",
  "Épouser quelqu’un qui vous fait rire ? Bruh, je ne peux pas me marier.",
  "Quel est cet endroit? Et comment diable suis-je arrivé ici ?",
  "Pourquoi pleurer pour quelqu’un quand on peut rire à côté de quelqu’un d’autre ?",
  "Vous avez de la chance que ma beauté ne puisse pas tuer.",
  "Tu aimes l’eau ? Alors, tu aimes déjà 70 % de ma personne !",
  "Tu sais ce qui serait très bien derrière ton prénom ? Mon nom de famille ! ",

]

export const MatchPage = () => {

  const name = listePrenoms[Math.floor(Math.random() * listePrenoms.length)];

  const [actualize, setActualize] = useState(false);
  const [filtreOn, setFiltreOn] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [firstClick, setFirstClick] = useState(-1);
  const [numberOfLike, setFirstLike] = useState(0);
  const [match, setMatch] = useState(false);

  const random = currentFilter !== null ? currentFilter : Math.floor(Math.random() * personnages.length);
  const description = listeDescr[Math.floor(Math.random() * listeDescr.length)];

  const changeCurrentFilter = (i) => {
    setCurrentFilter(i);
    if(firstClick === i) {
      setFiltreOn(false);
    } else {
      setFirstClick(i);
    }
  }

  const stopFilter = () => {
    setFiltreOn(false);
    setCurrentFilter(null);
  }

  const like = () => {

    const currentId = name;

    if(numberOfLike === 1 && !window.localStorage.getItem('match1')) {
      setMatch(true);
      window.localStorage.setItem('match1', currentId);
      setTimeout(() => {
        setMatch(false);
        setActualize(Math.random());
      }, 1200);
    } else {
      setActualize(Math.random());
    }
    setFirstLike(numberOfLike + 1);
  }

  return (
    <div className="matchPage">
      {match && <div className="matchAnim">
        <p> MATCH </p>
        <p> MATCH </p>
        <p> MATCH </p>
        </div>}
      {filtreOn && <div className="filtrepage">

        <img src={Arrow} onClick={() => setFiltreOn(false)} alt="fleche" style={{ position: "absolute", top: 20, left: 20 }} />

        <p className="filtertext"> Sélectionne le type de personnalité qui t’attire 🔥 </p>
        
        <div className="filterimages"> {personnages.map((e, i) => <div key={i} className={currentFilter === i ? "persActive" : "persInactive"} onClick={() => changeCurrentFilter(i)}> <img src={e.img} alt="photopers" /> <div className="imgFilter" /> </div>)}
        </div>
        {currentFilter !== null && <p className="filtertext"> {personnages[currentFilter].nom} {personnages[currentFilter].desc} </p>}
        <div className="filterbutton" onClick={stopFilter}> Ne pas filtrer </div>
      </div>}

      <div onClick={() => setFiltreOn(true)} style={{ width: "90%", display: "flex", alignItems: 'center' }}>
        <div className="filtre">
          <img src={Plus} alt="plus" className="plusIcon" />
          <p> Ajouter un filtre </p>
        </div>
        {currentFilter !== null && <img src={personnages[random].img} alt="pers" className="persfiltere" />}
      </div>

      <div className="personneMatch">
        <div className="topNameMatch">
          <p> {name}</p>
        </div>
        <img style={{ height: 'calc(100% - 75px)', width: '100%' }} src={"https://thispersondoesnotexist.com/image?" + actualize} alt="personne" />
        <div className="personneDesc">
          <p> {description}</p>
          <img src={personnages[random].img} alt="personnage" />
        </div>
      </div>

      <div className="likedislike">
        <div className="actionlike" onClick={() => setActualize(Math.random())}>
          <div>
            <img src={Bateau} alt="like" />
          </div>
          <p>Pas mon goût</p>
        </div>
        <div className="actionlike" onClick={like}>
          <div>
            <img src={Ticket} alt="like" />
          </div>
          <p>Aimer</p>
        </div>
      </div>

    </div>
  )
}
