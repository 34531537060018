import React, { useState } from 'react'
import Pencil from "../assets/Pencil.png"
import { personnages } from '../Personnages';
import { Button } from './Button';
import Avatar from "../assets/Avatar.png"
import VincentDedienne from "../assets/VincentDedienne.jpg"

export const Profil = ({ testAgain }) => {

  const [nom, setNom] = useState('Utilisateur 1');

  const [photoDeProfil, setPhotoDeProfil] = useState(Avatar);
  const [description, setDescription] = useState('');

  let indexPerso = window.localStorage.getItem('testDePerso');
  indexPerso = parseInt(indexPerso);


  return (
    <>
      <div className="profil">
        <div className="topText">
          <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} />
          <img src={Pencil} alt="pencil" />
        </div>

        <div className="ensembleprofil">
          <img className="resultttest" src={personnages[indexPerso].img} alt="persotest" />
          <img className="photoprofil" src={photoDeProfil} alt="profil" />
          <input onClick={() => setPhotoDeProfil(VincentDedienne)} type="file" />
        </div>

        <textarea placeholder='Ma description' value={description} onChange={(e) => setDescription(e.target.value)} />
        
        <Button action={testAgain} noarrow fontS={20} pourcent={100} contenu={"Refaire le test de personnalité"} />

      </div>
    </>
  )
}
