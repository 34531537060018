import React from 'react'
import Arrow from "../assets/ArrTop.png"

export const NavBar = ({ arrow, title }) => {
  return (
    <div style={{ background: 'black', color: 'white', boxSizing: 'border-box', padding: '0 30px', width: '100%', height: 60, display: 'flex', alignItems: 'center' }}>
        
        {arrow && <img src={Arrow} alt="arrow" onClick={arrow} />}
        <p className="titre_nav" style={{ marginLeft: arrow ? 25 : 0 }}> {title} </p>

    </div>
  )
}
