import Anne from "./assets/Anne.png";
import Alexandra from "./assets/Alexandra.jpg";
import Marina from "./assets/Marina.jpg";
import Soraya from "./assets/Soraya.jpg";
import Marc from "./assets/Marc.jpg";
import Chatal from "./assets/Chatal.JPG";

const personnages = [
    { nom: 'Marc', img: Marc, desc: 'est confiant et sûr de lui. Il pense avoir la science infuse et est perduadé qu’il est source de convoitise.' },
    { nom: 'Alexandra', img: Alexandra, desc: 'est...complètement tarée. Elle rêve de devenir le seul et unique centre d’intérêt de Marc.' },
    { nom: 'Chataléré', img: Chatal, desc: 'va droit au but. Elle est là que pour une seule chose, pécho un max de personnes.' },
    { nom: 'Soraya', img: Soraya, desc: 'est honnête et juste. C’est une femme de confiance qui reste fidèle à son coeur (de singe).' },
    { nom: 'Anne', img: Anne, desc: 'est honnête et altruiste. Grande romantique,il lui arrive de céder à la famille (euh folie).' },
    { nom: 'Marina', img: Marina, desc: 'est confiante et sportive. Elle n’assume pas sa sexualité mais est prête à y renoncer pour prouver son amour à Soraya.' },
]

const personnagesS2 = [
    { nom: 'Marc', img: Marc, desc: `est un enfant.
    Aime : être le centre de l’attention
    N’aime pas : suivre les règles` },
    { nom: 'Alexandra', img: Alexandra, desc: 'est une criminelle très dangereuse, sauf vis-à-vis de Marc.' },
    { nom: 'Chataléré', img: Chatal, desc: 'est toujours là pour pécho un max.' },
    { nom: 'Soraya', img: Soraya, desc: ' est loyale à son côté gorille. Elle fait confiance à son instinct et est très protectrice.' },
    { nom: 'Anne', img: Anne, desc: 'est nulle. C’est une tricheuse et une menteuse.' },
    { nom: 'Marina', img: Marina, desc: 'peut vite se montrer violente et jalouse, malgré son amour pour le gorille.' },
    { nom: 'Annick ', img: '', desc: 'n’est pas là pour l’argent. Du moins, pas au départ...' },
    { nom: 'Hervé', img: '', desc: 'est un lâche et une grosse balance. Il est un peu la p*te de tout le monde.' },
    { nom: 'William', img: '', desc: '? C’est qui William ?' },
    { nom: 'Carole', img: '', desc: 'est tout le temps enHervé. Une vraie traitresse.' },
    { nom: 'Patrice', img: '', desc: 'aime le pastis parce que l’alcool, c’est cool.' },
    { nom: 'Yvan', img: '', desc: 'est complotiste. Comme par hasard...' },
]

export { personnages, personnagesS2 };