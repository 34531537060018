import React from 'react'
import { Button } from './Button'
import "../css/Accueil1.css"
import AccueilGroupe from "../assets/AccueilGroup.png";

export const Accueil1 = ({prochaineEtape}) => {
  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>

        <img className="accueil_groupe" src={AccueilGroupe} alt="accueil" />
        <Button action={prochaineEtape} contenu={"Continuer"} />
        <p className="little_text"> Faites des rencontres grâce à l'application La Flamme </p>

    </div>
  )
}
