import React from 'react'
import Chat from "../assets/Chat.png"
import User from "../assets/User.png"
import Favorite from "../assets/Favorite.png"

export const BottomNavigator = ({ index, setNewIndex }) => {
    return (
        <div style={{ zIndex: 3, background: 'rgba(181, 22, 54,1)', position: 'fixed', display: 'flex', bottom: 0, paddingTop: 5, paddingBottom: 13, left: 0, width: '100%' }}>

            <div className="imgBottom" onClick={() => setNewIndex(1)}>
                <img src={Favorite} alt="bateau" />
                {index === 1 && <div className="dot" />}
            </div>
            <div className="separator" />
            <div className="imgBottom" onClick={() => setNewIndex(2)}>
                <img src={Chat} alt="bateau" />
                {index === 2 && <div className="dot" />}
            </div>
            <div className="separator" />
            <div className="imgBottom" onClick={() => setNewIndex(3)}>
                <img src={User} alt="bateau" />
                {index === 3 && <div className="dot" />}
            </div>

        </div>
    )
}
