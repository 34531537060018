import React, { useEffect, useState } from 'react'
import { NavBar } from './NavBar';
import Send from "../assets/Send.png"
import Personne1 from "../assets/Personne1.jpg"
import Personne2 from "../assets/Personne2.jpg"
import { Button } from './Button';

const fakeConv = [
  {
    id: 1,
    message: "Salut, tu cherches quoi ?",
    time: "12:00",
  },
  {
    id: 2,
    message: "Je cherche du sérieux, et toi ?",
    time: "14:00",
  },
  {
    id: 1,
    message: "Je sais pas trop..., j'hésite entre du sérieux et un environnement 3D",
    time: "15:00",
  },
  {
    id: 2,
    message: "Tu veux qu'on se rencontre pour en parler ?",
    time: "16:00",
  },
  {
    id: 1,
    message: "Non, ne viens pas sur Montpellier stp",
    time: "17:00",
  }
];


export const Chat = () => {

  const [message, setMessage] = useState('')
  const [ checkEmail, setCheckEmail ] = useState(false);
  const [ conv, setConv ] = useState([]);
  const [ email, setEmail ] = useState('');
  const [ conversations, setConversations] = useState([]);
  const [currentConvId, setCurrentConvId] = useState(null);

  const chooseConv = (id) => {

    let choosedConv = id === 1 || (id === 0 && conversations.length === 1)  ? fakeConv : window.localStorage.getItem('conversationMatch1') ? JSON.parse(window.localStorage.getItem('conversationMatch1')) : [];

    setConv(choosedConv);
    setCurrentConvId(id);
  }

  useEffect(() => {

    let finalConvs = [{
      proposition: true,
      id: 2,
      message: "Non, ne viens pas sur Montpellier stp",
      nom: "Melissa",
      img: Personne2
    }]

    let messageD = window.localStorage.getItem('conversationMatch1');

    if(window.localStorage.getItem('match1')) {
      //add an element at the first place of an array
      finalConvs.unshift({
        proposition: false,
        id: 1,
        img: Personne1,
        message: window.localStorage.getItem('conversationMatch1') ? JSON.parse(window.localStorage.getItem('conversationMatch1'))[JSON.parse(window.localStorage.getItem('conversationMatch1')).length - 1].message : "Vous n'avez pas encore parlé.",
        nom: window.localStorage.getItem('match1'),
      });
    }

    setConversations(finalConvs);
    
  }, [])

  const getCurrentDate = () => {
    const date = new Date();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    //format minutes to have 2 digits
    const minutesFormated = minutes < 10 ? `0${minutes}` : minutes;
    const time = hour + ":" + minutesFormated;

    return time;
  }

  const sendText = () => {
    
    if(message === '') return;
    //get current time as hour

    setConv([...conv, { id: 2, message: message, time: getCurrentDate() }])
    sendTextAI(message, [...conv, { id: 2, message: message, time: getCurrentDate() }]);
    setMessage('');
  }

  const sendTextAI = async (baseText, currentMessages) => {
    
    let options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51',
        'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
      },
      body: `[{"Text":"${baseText}"}]`
    };
    
    const pId = window.localStorage.getItem('flammeId');
    let rep = await fetch('https://microsoft-translator-text.p.rapidapi.com/translate?to%5B0%5D=en&api-version=3.0&profanityAction=NoAction&textType=plain', options)
    let data = await rep.json();
    console.log(data[0].translations[0].text,data);

    const textToAI = data[0].translations[0].text;
    
    options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51',
        'X-RapidAPI-Host': 'aeona3.p.rapidapi.com'
      }
    };
    
    rep = await fetch('https://aeona3.p.rapidapi.com/?text=' + textToAI + '&userId=' + pId, options)
    data = await rep.text();
    console.log(data);

    options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51',
        'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
      },
      body: `[{"Text":"${data}"}]`
    };
    
    rep = await fetch('https://microsoft-translator-text.p.rapidapi.com/translate?to%5B0%5D=fr&api-version=3.0&profanityAction=NoAction&textType=plain', options)
    data = await rep.json();
    console.log(data[0].translations[0].text,data);

    setConv([...currentMessages, { id: 1, message: data[0].translations[0].text, time: getCurrentDate() }])
    if(currentConvId === 0) {
      window.localStorage.setItem('conversationMatch1', JSON.stringify([...currentMessages, { id: 1, message: data[0].translations[0].text, time: getCurrentDate() }]));
    }
  }

  if (currentConvId === null) {
    return (
      <>
        {conversations.map((e, i) =>
          <div key={i} className="conversation" onClick={() => chooseConv(i)}>
            <img src={e.img} alt="pers" />
            <div className="conversationText">
              <p> {e.nom} </p>
              <p style={{ fontWeight: i === 1 ? 'normal' : 'bold' }}> {e.message} </p>
            </div>
          </div>)}
      </>
    )
  }

  return checkEmail ? 
      <div className="filtrepage">
        <p className="checkemailp">
          Rentrez votre email pour pouvoir accèder à la Jean Guile
        </p>
        <input value={email} onChange={(e) => setEmail(e.target.value)} className="checkemailinput" type="email" placeholder="Votre email" />
        <Button action={() => setCheckEmail(false)} noarrow fontS={20} pourcent={85} contenu={"Valider l'envoi"} />
      </div>
      : 
    <div style={{ position: "fixed", top: 0, left: 0, width: '100%', height: '100vh' }}>
      <NavBar title={conversations[currentConvId].nom} arrow={() => setCurrentConvId(null)} />
      <div className="chat">
        <div className="convplace">
          {conv.map((e, i) => 

            <div className="message" style={{ alignItems: e.id === 1 ? "flex-start" : "flex-end" }}>
              <p style={{ background: e.id === 1 ? 'rgba(0, 0, 0, 0.31)' : '#FDFDAC', color: e.id === 1 ? 'white' : 'black' }} className="messagetext">
                {e.message}
              </p>
              <p className="messageTime"> Reçu à {e.time} </p>
            </div>
          )}
        </div>
        {conversations[currentConvId].proposition ? 
        <div className="demandeJeanGuile">
          <p> Donnez vous rendez-vous à la Jean Guile ! </p>
          <div className="choixpersonnes">
            <img src={conversations[0].img} alt="photopers" className="imageP1" />
            <div className="imageP2" />
          </div>
          <div className="jyvais" onClick={() => setCheckEmail(true)}>
            J'y vais
          </div>
        </div>
        : <div className="ecriture">
          <input placeholder="Votre message" type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
          <img src={Send} alt="send" onClick={sendText}/>
        </div>}
      </div>      
    </div>
}
