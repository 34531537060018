import React, { useState } from 'react'
import { Button } from './components/Button';
import { personnages, personnagesS2 } from './Personnages';

const questions = [
    {
        questionText: 'Voler l’argent dans la caisse au Monopoly ?',
        reponses: [
            {
                reponseText: 'Sans aucune gêne',
                pointsIndex: [0, 1],
                pointsS2: [0, 4, 1, 7, 8, 9, 11],
                emoji: '😈'
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '😇'
            },
        ]
    },
    {
        questionText: 'Regarder dans le téléphone de son copain/sa copine ?',
        reponses: [
            {
                reponseText: 'Tous les soirs',
                pointsIndex: [1],
                pointsS2: [1, 5, 7, 8, 11],
                emoji: '📱',
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '✨',
            },
        ]
    },
    {
        questionText: 'Ton téléphone est-il le prolongement de ta main ?',
        reponses: [
            {
                reponseText: 'Je suis un(e) vrai(e) influenceur(euse)',
                pointsIndex: [1, 2],
                pointsS2: [1, 2, 7, 8, 11],
                emoji: '📱',
            },
            {
                reponseText: 'Pas du tout',
                emoji: '✨',
            },
        ]
    },
    {
        questionText: 'Fais-tu exprès d’arriver en retard pour qu’on te remarque ?',
        reponses: [
            {
                reponseText: 'Of course, je suis une star',
                pointsIndex: [0, 1, 2],
                pointsS2: [0, 1, 2, 8, 11],
                emoji: '✨',
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '⚡️'
            },
        ]
    },
    {
        questionText: 'Le footing du matin en vacances ?',
        reponses: [
            {
                reponseText: 'Quelle question, une évidence',
                pointsIndex: [3, 5],
                pointsS2: [3, 5, 9, 11],
                emoji: '✨',
            },
            {
                reponseText: 'Je préfère me lever à midi...',
                emoji: '🛏️'
            },
        ]
    },
    {
        questionText: 'Serais-tu capable d\'accueillir des animaux chez toi même si ton propriétaire ne veut pas ?',
        reponses: [
            {
                reponseText: 'Plutôt deux fois qu’une',
                pointsIndex: [3, 4, 5],
                pointsS2: [3, 4, 5, 6, 10, 11],
                emoji: '🐶',
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '⚡️'
            },
        ]
    },
    {
        questionText: 'Je croise une personne dans la rue qui me demande de l’argent :',
        reponses: [
            {
                reponseText: 'Je pourrais lui donner mon pull',
                pointsIndex: [3, 4, 5],
                pointsS2: [3, 4, 5, 6, 7, 10],
                emoji: '🧥',
            },
            {
                reponseText: 'Je suis un rat',
                emoji: '🐀'
            },
        ]
    },
    {
        questionText: 'Le sport de chambre ?',
        reponses: [
            {
                reponseText: 'Un jour sans et j’en tremble',
                pointsIndex: [2],
                pointsS2: [2, 10],
                emoji: '🏋️‍♀️',
            },
            {
                reponseText: 'Sans plus…',
                emoji: '🛏️'
            },
        ]
    },
    {
        questionText: 'Le romantisme à la Roméo et Juliette ?',
        reponses: [
            {
                reponseText: 'J\'en rêve',
                pointsIndex: [0, 1, 3, 4, 5],
                pointsS2: [0, 1, 3, 4, 5, 6, 7, 8, 10],
                emoji: '💘',
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '⚡️'
            },
        ]
    },
    {
        questionText: 'Avoir une relation avec quelqu’un qui partage ton ADN ?',
        reponses: [
            {
                reponseText: 'J’y ai déjà pensé',
                pointsIndex: [2, 4],
                pointsS2: [2, 4, 6, 7],
                emoji: '👨‍👩‍👧‍👦',
            },
            {
                reponseText: 'Jamais de la vie',
                emoji: '⚡️'
            },
        ]
    },
    {
        questionText: 'Si un(e) mec/fille regarde ton copain/ta copine dans la rue :',
        reponses: [
            {
                reponseText: 'Pas touche, c\'est à moi',
                pointsIndex: [1],
                pointsS2: [1, 3, 6, 7],
                emoji: '👀',
            },
            {
                reponseText: 'Tant qu’il regarde pas, c’est pas grave',
                emoji: '✨'
            },
        ]
    },
    {
        questionText: 'Sortir le vendredi soir ?',
        reponses: [
            {
                reponseText: 'Toujours, je peux pas rester en place',
                pointsIndex: [0, 1, 2, 5],
                pointsS2: [0, 1, 2, 5, 6, 8, 10],
                emoji: '🍻',
            },
            {
                reponseText: 'Je préfère une tisane dans mon lit',
                emoji: '🛏️'
            },
        ]
    },
    {
        questionText: 'Ton/ta meilleur(e) ami(e) a trompé son copain/sa copine :',
        reponses: [
            {
                reponseText: 'Je balance direct',
                pointsS2: [1, 2, 5, 7, 8, 11],
                emoji: '🗣️',
            },
            {
                reponseText: 'Je dis rien, c’est mon/ma meilleur(e) ami(e)',
                emoji: '✨'
            },
        ]
    },
    {
        questionText: 'J’ai tendance...',
        reponses: [
            {
                reponseText: 'A voir le verre à moitié plein',
                pointsS2: [0, 1, 2, 3, 4, 5, 7, 8, 9, 11],
                emoji: '🍷',
            },
            {
                reponseText: 'A boir le verre à moitié plein',
                emoji: '✨'
            },
        ]
    },
    {
        questionText: 'Participer au flambeau pour l\'énorme somme de 450€ :',
        reponses: [
            {
                reponseText: 'A moi la richesse',
                pointsS2: [0, 1, 2, 7, 8, 9],
                emoji: '💰',
            },
            {
                reponseText: 'L\'argent c\'est que du bonus',
                emoji: '✨'
            },
        ]
    },
    {
        questionText: 'Si je dois jouer au poker :',
        reponses: [
            {
                reponseText: 'Poker face, mentir, manipuler, tout est bon pour gagner',
                pointsS2: [1, 2, 4, 7, 9, 10],
                emoji: '🃏',
            },
            {
                reponseText: 'Le hasard fera en sorte que je gagne ou non',
                emoji: '✨'
            },
        ]
    },
    {
        questionText: 'Poster une photo de soi sur Instagram ?',
        reponses: [
            {
                reponseText: 'Oui mais jamais sans un filtre',
                pointsS2: [0, 1, 2, 8],
                emoji: '📸',
            },
            {
                reponseText: 'Je ne poste pas ou alors je suis rarement seul(e)',
                emoji: '✨'
            },
        ]
    },
]

const randomEmoji = ['😃', '😎', '🔥', '✨', '⚡️'];

export const TestDePersonnalité = ({ saison, goNext }) => {

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentScores, setCurrentScores] = useState(new Array(saison === 1 ? personnages.length : personnagesS2.length).fill(0));
    const [isFinished, setIsFinished] = useState(false);
    const [firstApp, setFirstApp] = useState(true);

    let nbQuestions = saison === 1 ? questions.length - 5 : questions.length;

    const finish = () => {
        
        let max = -1;
        let index = null;
        for(let i = 0; i < currentScores.length; i++) {
            if(currentScores[i] > max) {max = currentScores[i]; index = i;}
        }

        window.localStorage.setItem('testDePerso',index);
        setTimeout(() => setFirstApp(false), 2000);

        setCurrentScores(index);
        setIsFinished(true);
    }

    const replyToQuestion = (index) => {
        let newScores = [...currentScores];
        const question = questions[currentQuestion];
        const reponse = question.reponses[0];

        if (index === 0) {
            if (saison === 1) {
                reponse.pointsIndex.forEach((i) => newScores[i]++);
            } else {
                reponse.pointsS2.forEach((i) => newScores[i]++);
            }
        } else {
            if (saison === 1) {
                newScores = newScores.map((score) => score + 1);
                reponse.pointsIndex.forEach((i) => newScores[i]--);
            } else {
                newScores = newScores.map((score) => score + 1);
                reponse.pointsS2.forEach((i) => newScores[i]--);
            }
        }
        setCurrentScores(newScores);

        if (currentQuestion < nbQuestions - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            finish();
        }
    }

    let color = saison === 1 ? '#FDFDAC' : 'black';

    return isFinished ?
        <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', flexDirection: 'column', zIndex: 1, justifyContent: 'center', top: 0, left: 0, width: '100%', height: '100vh' }}>

            <img src={personnages[currentScores].img} alt="profil" style={{ width: '80%', maxWidth: 340, borderRadius: 500, border: '10px solid black'}} /> 
           <p className="profilFit"> {firstApp ? <> Ton profil correspond à la personnalité de <span style={{ color: '#FDFDAC' }}>{personnages[currentScores].nom}</span> </> : 
           
           <> <span style={{ color: '#FDFDAC' }}>{personnages[currentScores].nom}</span> {personnages[currentScores].desc}  </>}</p>
            {!firstApp && <Button action={goNext} contenu={"Continuer"} /> }
        </div>
        :
        <div style={{ marginTop: 100, display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ color: color, fontWeight: 600, width: '90%', marginBottom: 20, textAlign: 'center', fontSize: 30, lineHeight: '140.5%' }}> {questions[currentQuestion].questionText} </div>

            <div className="question" style={{ background: '#720E22', marginBottom: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, fontSize: 22, width: '90%', height: 85, display: 'flex', justifyContent: 'center', padding: '0px 20px', boxSizing: 'border-box', alignItems: 'center', color: 'white' }} onClick={() => replyToQuestion(0)}>
                <p style={{ marginRight: 12 }}> {questions[currentQuestion].reponses[0].emoji} </p>
                <p style={{ textAlign: 'center' }}> {questions[currentQuestion].reponses[0].reponseText} </p>
            </div>
            <div className="question" style={{ background: '#720E22', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, fontSize: 22, width: '90%', height: 85, display: 'flex', justifyContent: 'center', padding: '0px 20px', boxSizing: 'border-box', alignItems: 'center', color: 'white' }} onClick={() => replyToQuestion(1)}>
                <p style={{ marginRight: 12 }}> {questions[currentQuestion].reponses[1].emoji} </p>
                <p style={{ textAlign: 'center' }}> {questions[currentQuestion].reponses[1].reponseText} </p>
            </div>
            <p style={{ opacity: 0.5, fontSize: 20 }}> {currentQuestion + 1}/{nbQuestions} </p>
        </div>

}
